(function (wpm, $, undefined) {

	let fBUserData;

	wpm.getRandomEventId = function () {
		return (Math.random() + 1).toString(36).substring(2);
	}

	wpm.getFbUserData = function () {
		// We need the first one for InitiateCheckout
		// where getting the user_data from the browser is too slow
		// using wpm.getCookie(), so we cache the user_data earlier.
		// And we need the second one because the ViewContent hit happens too fast
		// after adding a variation to the cart because the function to cache
		// the user_data is too slow. But we can get the user_data using wpm.getCookie()
		// because we don't move away from the page and can wait for the browser
		// to get it.
		if (fBUserData) {
			return fBUserData;
		} else {
			return wpm.getFbUserDataFromBrowser();
		}
	}

	wpm.setFbUserData = function () {
		fBUserData = wpm.getFbUserDataFromBrowser();
	}

	wpm.getFbUserDataFromBrowser = function () {
		return {
			"fbp"              : wpm.getCookie('_fbp'),
			"fbc"              : wpm.getCookie('_fbc'),
			"client_user_agent": navigator.userAgent
		}
	}

	wpm.fbViewContent = function (product) {

		if (!window.fbq) return;

		let eventId = wpm.getRandomEventId();

		fbq("track", "ViewContent", {
			"content_type": "product",
			"content_name": product.name,
			// "content_category": product.category,
			"content_ids": product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
			"currency"   : wpmDataLayer.shop.currency,
			"value"      : product.price,
		}, {
			"eventID": eventId,
		});

		product['currency'] = wpmDataLayer.shop.currency;

		jQuery(document).trigger('wpmFbCapiEvent', {
			"event_name"      : "ViewContent",
			"event_id"        : eventId,
			"user_data"       : wpm.getFbUserData(),
			"product_data"    : product,
			"product_id"      : product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
			"event_source_url": window.location.href
		});
	}

}(window.wpm = window.wpm || {}, jQuery));

jQuery(function () {

	if (wpmDataLayer?.pixels?.facebook) {

		// AddToCart event
		jQuery(document).on('wpmAddToCart', function (event, product) {

			// console.log('firing facebook ads AddToCart event');
			// console.log(product);
			// console.log('value: ' + product.quantity * product.price);

			if (!window.fbq) return;

			let eventId = wpm.getRandomEventId();

			// console.log('eventId: ' + eventId);

			fbq("track", "AddToCart", {
				"content_type": "product",
				"content_name": product.name,
				"content_ids" : product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				"value"       : parseFloat(product.quantity * product.price),
				"currency"    : product.currency,
			}, {
				"eventID": eventId,
			});

			product['currency'] = wpmDataLayer.shop.currency;

			jQuery(document).trigger('wpmFbCapiEvent', {
				"event_name"      : "AddToCart",
				"event_id"        : eventId,
				"user_data"       : wpm.getFbUserData(),
				"product_data"    : product,
				"product_id"      : product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				"event_source_url": window.location.href
			});
		});

		// InitiateCheckout event
		jQuery(document).on('wpmBeginCheckout', function (event) {

			// console.log('firing facebook ads InitiateCheckout event');

			if (!window.fbq) return;

			let eventId = wpm.getRandomEventId();

			fbq("track", "InitiateCheckout", {}, {
				"eventID": eventId,
			});


			jQuery(document).trigger('wpmFbCapiEvent', {
				"event_name"      : "InitiateCheckout",
				"event_id"        : eventId,
				"user_data"       : wpm.getFbUserData(),
				"event_source_url": window.location.href
			});
		});

		// AddToWishlist event
		jQuery(document).on('wpmAddToWishlist', function (event, product) {

			// console.log('firing facebook ads AddToWishlist event');
			// console.log(product);

			if (!window.fbq) return;

			let eventId = wpm.getRandomEventId();

			fbq("track", "AddToWishlist", {
				"content_type": "product",
				"content_name": product.name,
				"content_ids" : product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				"value"       : parseFloat(product.quantity * product.price),
				"currency"    : product.currency,
			}, {
				"eventID": eventId,
			});

			product['currency'] = wpmDataLayer.shop.currency;

			jQuery(document).trigger('wpmFbCapiEvent', {
				"event_name"      : "AddToWishlist",
				"event_id"        : eventId,
				"user_data"       : wpm.getFbUserData(),
				"product_data"    : product,
				"product_id"      : product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				"event_source_url": window.location.href
			});
		});

		// ViewContent event
		jQuery(document).on('wpmViewItem', function (event, product) {

			// console.log('firing facebook ads ViewContent event');
			// console.log(product);

			if (!window.fbq) return;

			wpm.fbViewContent(product);
		});
	}
})

jQuery(window).on('load', function () {

	if (wpmDataLayer?.pixels?.facebook) {

		wpm.setFbUserData();

		wpmExists().then(function () {

			try {
				if (wpmDataLayer.shop.page_type === 'product' && wpmDataLayer.shop.product_type !== 'variable' && wpm.getMainProductIdFromProductPage()) {

					let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage());

					// console.log('fbq ViewContent');
					// console.log(product);

					wpm.fbViewContent(product);

				} else if (wpmDataLayer.shop.page_type === 'search') {

					if (!window.fbq) return;

					let eventId = wpm.getRandomEventId();

					fbq("track", "Search", {}, {
						"eventID": eventId,
					});

					jQuery(document).trigger('wpmFbCapiEvent', {
						"event_name"      : "Search",
						"event_id"        : eventId,
						"user_data"       : wpm.getFbUserData(),
						"event_source_url": window.location.href
					});
				}
			} catch (e) {
				console.log(e);
			}
		})
	}
})
