jQuery(function () {

	if (wpmDataLayer?.pixels?.google?.ads?.dynamic_remarketing?.status) {

		// view_item_list event
		jQuery(document).on('wpmViewItemList', function (event, product) {

			if (
				wpmDataLayer.general.variationsOutput &&
				product.isVariable &&
				wpmDataLayer.pixels.google.ads.dynamic_remarketing.send_events_with_parent_ids === false
			) return;

			// try to prevent that WC sends cached hits to Google
			if (!product) return;

			// console.log('firing google view_item_list event');
			// console.log(product);

			// console.log('send to: ' + wpmDataLayer.pixels.google.ads.conversionIds);

			if (googleConfigConditionsMet('ads')) gtag('event', 'view_item_list', {
				send_to: wpmDataLayer.pixels.google.ads.conversionIds,
				items  : [{
					id                      : product.dyn_r_ids[wpmDataLayer.pixels.google.ads.dynamic_remarketing.id_type],
					google_business_vertical: wpmDataLayer.pixels.google.ads.google_business_vertical,
				}]
			});
		});

		// add_to_cart event
		jQuery(document).on('wpmAddToCart', function (event, product) {

			// console.log('firing google ads add_to_cart event');
			// console.log(product);
			// console.log(wpmDataLayer.pixels.google.ads.conversionIds);
			// console.log('dyn_r_id: ' + product.dyn_r_ids[wpmDataLayer.pixels.google.ads.dynamic_remarketing.id_type]);
			// console.log('dyn_r_id: ' + product.dyn_r_ids['gpf']);

			gtag("event", "add_to_cart", {
				"send_to": wpmDataLayer.pixels.google.ads.conversionIds,
				"value"  : product.quantity * product.price,
				"items"  : [{
					"id"                      : product.dyn_r_ids[wpmDataLayer.pixels.google.ads.dynamic_remarketing.id_type],
					"quantity"                : product.quantity,
					"price"                   : product.price,
					"google_business_vertical": wpmDataLayer.pixels.google.ads.google_business_vertical
				}]
			});
		});

		// view_item event
		jQuery(document).on('wpmViewItem', function (event, product) {

			// console.log('firing google ads view_item event');
			// console.log(product);
			// console.log(wpmDataLayer.pixels.google.ads.conversionIds);
			// console.log('dyn_r_id: ' + product.dyn_r_ids[wpmDataLayer.pixels.google.ads.dynamic_remarketing.id_type]);
			// console.log('dyn_r_id: ' + product.dyn_r_ids['gpf']);

			gtag("event", "view_item", {
				"send_to": wpmDataLayer.pixels.google.ads.conversionIds,
				"value"  : product.quantity * product.price,
				"items"  : [{
					"id"                      : product.dyn_r_ids[wpmDataLayer.pixels.google.ads.dynamic_remarketing.id_type],
					"quantity"                : 1,
					"price"                   : product.price,
					"google_business_vertical": wpmDataLayer.pixels.google.ads.google_business_vertical
				}]
			});
		});
	}
})

jQuery(window).on('load', function () {

	wpmExists().then(function () {

		try {
			if (wpmDataLayer.shop.page_type === 'product' && wpmDataLayer.shop.product_type !== 'variable' && wpm.getMainProductIdFromProductPage()) {

				// console.log('productId: ' + wpm.getMainProductIdFromProductPage());

				let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage());

				gtag("event", "view_item", {
					"send_to": wpmDataLayer.pixels.google.ads.conversionIds,
					"value"  : 1 * product.price,
					"items"  : [{
						"id"                      : product.dyn_r_ids[wpmDataLayer.pixels.google.ads.dynamic_remarketing.id_type],
						"google_business_vertical": wpmDataLayer.pixels.google.ads.google_business_vertical
					}]
				});
			} else if (wpmDataLayer.shop.page_type === 'search') {

				let products = [];

				for (const [key, product] of Object.entries(wpmDataLayer.products)) {

					if (
						wpmDataLayer.general.variationsOutput &&
						product.isVariable &&
						wpmDataLayer.pixels.google.ads.dynamic_remarketing.send_events_with_parent_ids === false
					) return;

					products.push({
						"id"                      : product.dyn_r_ids[wpmDataLayer.pixels.google.ads.dynamic_remarketing.id_type],
						"google_business_vertical": wpmDataLayer.pixels.google.ads.google_business_vertical
					});
				}

				// console.log(products);

				gtag("event", "view_search_results", {
					"send_to": wpmDataLayer.pixels.google.ads.conversionIds,
					// "value"  : 1 * product.price,
					"items": products
				});
			}
		} catch (e) {
			console.log(e);
		}
	})
})
